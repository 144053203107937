export enum EDITOR_EVENTS {
  GFPP_CLICKED = 'componentGfppClicked',
  APP_WIDGET_GFPP_CLICKED = 'widgetGfppClicked',
  COMPONENT_DELETED = 'componentDeleted',
  SITE_PUBLISHED = 'siteWasPublished',
  SITE_FIRST_SAVE = 'siteWasFirstSaved',
  GLOBAL_DESIGN_PRESET_CHANGED = 'globalDesignPresetChanged',
  WIDGET_PASTED = 'widgetPasted'
}

export enum NOTIFICATION_EVENTS {
  PAYMENT_FORM_ADDED = 'paymentFormAdded',
  SUCCESS_MESSAGE_DELETED = 'messageRole',
  DOWNLOAD_MESSAGE_DELETED = 'downloadMessageRole',
  EMAIL_FIELD_DELETED = 'email',
  SUBMISSION_BUTTON_DELETED = 'buttonRole'
}

