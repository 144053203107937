import CoreApi from '../core-api'
import RemoteApi from '../../../panels/commons/remote-api'
import { DEFAULT_RESTRICTIONS, PremiumRestriction } from '../../../constants/premium'
import * as _ from 'lodash'

const ASCEND_APP_DEF_ID = 'ee21fe60-48c5-45e9-95f4-6ca8f9b1c9d9'

export default class PremiumApi {
  private boundEditorSDK: any
  private coreApi: CoreApi
  private experiments: any
  private remoteApi: RemoteApi

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.remoteApi = remoteApi
    this.experiments = experiments
  }

  public async hasAscendPackage() {
    const vendorProductId = await this.boundEditorSDK.info.getVendorProductId({
      appDefinitionId: ASCEND_APP_DEF_ID,
    })

    return !_.isEmpty(vendorProductId)
  }

  public async getPremiumRestrictions(): Promise<{ restrictions: PremiumRestriction }> {
    const platformizeRequest = this.experiments.enabled(
      'specs.cx.FormBuilderPlatformizedRestrictions'
    )
      ? this.remoteApi.getPremuimRestrictionsPlatformized
      : this.remoteApi.getPremiumRestrictions

    return (
      (!(await this.coreApi.isTemplate()) && platformizeRequest()) ||
      Promise.resolve({ restrictions: DEFAULT_RESTRICTIONS })
    )
  }
}
